import { configureStore, combineReducers } from '@reduxjs/toolkit'

import * as reducers from './modules'
import { initialState } from './modules/user/user.slice'

const combinedReducer = combineReducers(reducers)

export const rootReducer = (state, action) => {
  if (action.type === 'user/logout') {
    const { form } = state
    initialState.mounted = true
    // eslint-disable-next-line no-param-reassign
    state = { form }
  }
  return combinedReducer(state, action)
}

export default configureStore({
  reducer: rootReducer,
  devTools: process.env.NODE_ENV === 'development'
})

export type AppState = ReturnType<typeof rootReducer>
// export type AppDispatch = typeof store.dispatch
