import { AppState } from '~/state/store'

import { RolesUnion } from '~/constants/roles'

import { Business } from '~/models/Business'
import { Dealer } from '~/models/Dealer'

export const getUserName = (state): string => state.user.name

export const getUserPhone = (state: AppState): string => state.user.phone

export const getMyBusiness = (state: AppState): Business => state.user.business

export const getDealer = (state: AppState): Dealer => state.user.dealer

export const isLogged = (state: AppState): boolean => {
  const { user: { token } = {} } = state

  return !!token
}

export const isMounted = (state: AppState): boolean => {
  const { user: { mounted } = {} } = state

  return mounted
}

export const getRole = (state: AppState): string => {
  const { user: { role } = {} } = state

  return role
}

export const hasRole = (role: RolesUnion) => (state: AppState): boolean => {
  const { user } = state

  return user.role === role
}

export const hasDealerPaid = (state: AppState): boolean => {
  const { user } = state

  return user.dealer?.status && user.dealer?.status !== 'draft'
}

export const userHasDealer = (state: AppState): boolean => {
  const { user } = state

  return !!user.dealer?.status
}

export const hasBusinessPaid = (state: AppState): boolean => {
  const { user } = state

  return user.business?.status && user.business?.status !== 'draft'
}

export const userHasBusiness = (state: AppState): boolean => {
  const { user } = state

  return !!user.business?.status
}
