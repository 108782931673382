const typeRedirects = [
  {
    old: 'motorhomes',
    new: 'Motorhomes'
  },
  {
    old: 'caravans',
    new: 'Caravans'
  },
  {
    old: 'camper-trailers',
    new: 'Camper%20Trailers'
  },
  {
    old: 'vehicles',
    new: 'Vehicles'
  },
  {
    old: 'accessories',
    new: 'Parts%20&%20Accessories'
  }
]

export default typeRedirects
