import React, { useState } from 'react'

import { toast, Toaster, ToastBar } from 'react-hot-toast'

import { Box } from '@material-ui/core'

import ProgressBar from '~/components/shared/toastProgressBar/ProgressBar'

export default function ToasterContainer({ position, duration }) {
  return (
    <Toaster
      position={position}
      containerStyle={{
        top: '100px'
      }}
      toastOptions={{
        duration,
        success: {
          className: 'toast toastSuccess'
        },
        error: {
          className: 'toast toastError'
        }
      }}
    >
      {(t) => {
        return (
          <ToastBar toast={t} style={{ overflow: 'hidden' }}>
            {({ message }) => {
              const [isHover, setHover] = useState(false)
              return (
                <Box
                  onMouseEnter={() => setHover(true)}
                  onMouseLeave={() => setHover(false)}
                  sx={{
                    width: '100%',
                    padding: '8px 4px 8px 20px',
                    alignItems: 'center',
                    display: 'flex',
                    height: '100%'
                  }}
                >
                  {message}
                  {t.type !== 'loading' && (
                    <button
                      type="button"
                      className="toastButton"
                      onClick={() => toast.dismiss(t.id)}
                    >
                      &times;
                    </button>
                  )}
                  <ProgressBar
                    isRunning={!isHover}
                    isIn
                    delay={t.duration}
                    closeToast={() => toast.dismiss(t.id)}
                  />
                </Box>
              )
            }}
          </ToastBar>
        )
      }}
    </Toaster>
  )
}
