import { createAsyncThunk } from '@reduxjs/toolkit'

import Deserializer from '~/services/Deserializer'
import EmpriseGroup from '~/services/EmpriseGroup'

import { Dealer } from '~/models/Dealer'
import { Pagy } from '~/models/Pagy'

export const fetchDealers = createAsyncThunk<
  { data: Dealer[]; pagy: Pagy },
  any
>('dealers::fetch', async (search) => {
  const { pagy, dealers } = await EmpriseGroup.getDealers(search)
  const data = await Deserializer.deserialize(dealers)

  return { data, pagy }
})
