import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios'

import Deserializer from '~/services/Deserializer'

import { merge } from './functions'

interface RequestConfig extends AxiosRequestConfig {
  deserialize?: boolean
}

type Handler = (url: string, config?: RequestConfig) => Promise<any>

type Handlers = {
  get: Handler
  post: Handler
  put: Handler
  patch: Handler
  del: Handler
}

class Api {
  private config: any
  private instance: any

  constructor(config: RequestConfig) {
    this.config = config
    this.instance = axios.create(config)
  }

  update(config: RequestConfig): void {
    this.instance = axios.create(merge(this.config, config))
  }

  setAuthToken(authToken?: string): void {
    if (authToken) {
      this.instance.defaults.headers.common.Authorization = `Bearer ${authToken}`
    } else delete this.instance.defaults.headers.common.Authorization
  }

  /**
   * Set instance default header
   *
   * @param header header name
   * @param value header value
   */
  setHeader(header: string, value: string): void {
    this.instance.defaults.headers.common[header] = value
  }

  appendErrorInterceptor = (interceptor: any): void => {
    this.instance.interceptors.response.use((r: any) => r, interceptor)
  }

  makeRequest(
    method: string,
    url: string,
    options: RequestConfig = {}
  ): Promise<AxiosResponse | Error> {
    const headers = { ...this.instance.defaults.headers, ...options.headers }
    const { deserialize, ...restOptions } = options

    return this.instance({
      ...restOptions,
      method,
      url,
      headers
    })
      .then(async (resp: AxiosResponse) => {
        if (deserialize) {
          const data = await Deserializer.deserialize(resp?.data)
          return data
        }
        return resp?.data
      })
      .catch(({ response }: AxiosError) => Promise.reject(response?.data))
  }
  get: Handler = (url, config) => this.makeRequest('get', url, config)

  post: Handler = (url, config) => this.makeRequest('post', url, config)

  put: Handler = (url, config) => this.makeRequest('put', url, config)

  patch: Handler = (url, config) => this.makeRequest('patch', url, config)

  del: Handler = (url, config) => this.makeRequest('delete', url, config)

  requestHandlers: Handlers = {
    get: this.get,
    post: this.post,
    put: this.put,
    patch: this.patch,
    del: this.del
  }
}

export default Api
