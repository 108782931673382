import { useEffect } from 'react'

import { useDispatch } from 'react-redux'

import EmpriseGroup from '~/services/EmpriseGroup'
import { authtorize } from '~/state/modules/user'
import { actions } from '~/state/modules/user/user.slice'

import { IS_BROWSER, STORAGE } from '~/constants'

const jwt = IS_BROWSER && localStorage.getItem(STORAGE.authToken)

if (jwt) {
  EmpriseGroup.setAuthToken(jwt)
}

const useAutoLogin = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    const getUser = async () => {
      try {
        if (jwt) {
          EmpriseGroup.setAuthToken(jwt)
          // @ts-ignore
          await dispatch<Promise<any>>(authtorize({ jwt }))
        }

        dispatch(actions.login({ mounted: true }))
      } catch (error) {
        console.log('error', error)
      }
    }

    getUser()
  }, [])
}

export default useAutoLogin
