import { useEffect } from 'react'

import { isSafari } from '~/constants/devices'

const useIntersectionObserver = () => {
  useEffect(() => {
    const setupPolyfill = async () => {
      try {
        await import('intersection-observer')
      } catch (error) {
        Error('Loading intersection-observer failed')
      }
    }

    if (isSafari) {
      setupPolyfill()
    }
  }, [])
}

export default useIntersectionObserver
