import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { Package } from '~/models/Package'

import { fetchFeatures, fetchPackages } from './package.actions'

export interface State {
  id: string
  features: number[]
  packages: Package[]
  loading: boolean
  loaded: boolean
  loadingFeatures: boolean
  loadedFeatures: boolean
}

const initialState: State = {
  id: null,
  features: [],
  packages: [],
  loaded: false,
  loading: true,
  loadingFeatures: true,
  loadedFeatures: false
}

const packageSlice = createSlice({
  name: 'package',
  initialState,
  reducers: {
    setPackages: (state, { payload }): void => {
      state.packages = payload
    },
    setPackage: (state, { payload }: { payload: string }): void => {
      if (state.id !== payload) {
        state.id = payload
        state.features = []
      }
    },
    togglePackageFeature: (state, action: PayloadAction<number>): void => {
      const featureIndex = state.features.findIndex(
        (id) => id === action.payload
      )

      if (featureIndex !== -1) {
        state.features.splice(featureIndex, 1)
      } else {
        state.features.push(action.payload)
      }
    },
    resetPackage: (): State => ({
      ...initialState
    })
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPackages.pending, (state) => {
      state.loading = true
    })
    builder.addCase(fetchPackages.fulfilled, (state, { payload }) => {
      state.packages = payload
      state.loading = false
      state.loaded = true
    })
    builder.addCase(fetchPackages.rejected, (state) => {
      state.loading = false
    })
    builder.addCase(fetchFeatures.pending, (state) => {
      state.loadingFeatures = true
    })
    builder.addCase(fetchFeatures.fulfilled, (state, { payload }) => {
      const updateFeatures = state.packages?.map((item) => {
        return { ...item, advanced_features: payload }
      })
      state.packages = updateFeatures
      state.loadingFeatures = false
      state.loadedFeatures = true
    })
    builder.addCase(fetchFeatures.rejected, (state) => {
      state.loadingFeatures = false
    })
  }
})

export const { actions } = packageSlice
export default packageSlice.reducer
