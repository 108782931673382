const categories = [
  {
    categoryID: 34,
    categoryName: 'Boat Reviews',
    superCategoryID: 1,
    siteID: null,
    subcategories: [
      {
        categoryID: 37,
        categoryName: 'Power Boats',
        superCategoryID: 1,
        siteID: null
      },
      {
        categoryID: 35,
        categoryName: 'Trailer Boat',
        superCategoryID: 1,
        siteID: null
      },
      {
        categoryID: 96,
        categoryName: 'Sailboat',
        superCategoryID: 1,
        siteID: null
      },
      {
        categoryID: 97,
        categoryName: 'Preloved',
        superCategoryID: 1,
        siteID: null
      }
    ]
  },
  {
    categoryID: 98,
    categoryName: 'Boat Brands',
    superCategoryID: null,
    siteID: 7,
    subcategories: [
      {
        categoryID: 99,
        categoryName: 'Maritimo',
        superCategoryID: 98,
        siteID: 7
      },
      {
        categoryID: 100,
        categoryName: 'Fleming',
        superCategoryID: 98,
        siteID: 7
      },
      {
        categoryID: 101,
        categoryName: 'Haines',
        superCategoryID: 98,
        siteID: 7
      },
      {
        categoryID: 102,
        categoryName: 'Horizon',
        superCategoryID: 98,
        siteID: 7
      },
      {
        categoryID: 103,
        categoryName: 'Ocean Alexander',
        superCategoryID: 98,
        siteID: 7
      },
      {
        categoryID: 104,
        categoryName: 'Palm Beach',
        superCategoryID: 98,
        siteID: 7
      },
      {
        categoryID: 105,
        categoryName: 'Riviera',
        superCategoryID: 98,
        siteID: 7
      },
      {
        categoryID: 106,
        categoryName: 'Bar Crusher',
        superCategoryID: 98,
        siteID: 7
      },
      {
        categoryID: 107,
        categoryName: 'New Quintrex',
        superCategoryID: 98,
        siteID: 7
      },
      {
        categoryID: 108,
        categoryName: 'Stabicraft',
        superCategoryID: 98,
        siteID: 7
      }
    ]
  },
  {
    categoryID: 38,
    categoryName: 'Engines',
    superCategoryID: null,
    siteID: null,
    subcategories: [
      {
        categoryID: 117,
        categoryName: 'Outboard',
        superCategoryID: 38,
        siteID: null
      },
      {
        categoryID: 118,
        categoryName: 'Inbound',
        superCategoryID: 38,
        siteID: null
      },
      {
        categoryID: 119,
        categoryName: 'Other',
        superCategoryID: 38,
        siteID: null
      },
      {
        categoryID: 39,
        categoryName: 'Mercury',
        superCategoryID: 38,
        siteID: null
      },
      {
        categoryID: 40,
        categoryName: 'Yamaha',
        superCategoryID: 38,
        siteID: null
      },
      {
        categoryID: 41,
        categoryName: 'Honda',
        superCategoryID: 38,
        siteID: null
      },
      {
        categoryID: 42,
        categoryName: 'Tohatsu',
        superCategoryID: 38,
        siteID: null
      },
      {
        categoryID: 43,
        categoryName: 'Suzuki',
        superCategoryID: 38,
        siteID: null
      },
      {
        categoryID: 44,
        categoryName: 'Evenrude',
        superCategoryID: 38,
        siteID: null
      },
      {
        categoryID: 45,
        categoryName: 'Skania',
        superCategoryID: 38,
        siteID: null
      },
      {
        categoryID: 46,
        categoryName: 'Volvo',
        superCategoryID: 38,
        siteID: null
      },
      {
        categoryID: 47,
        categoryName: 'Yanmar',
        superCategoryID: 38,
        siteID: null
      }
    ]
  },
  {
    categoryID: 2,
    categoryName: 'Gear Reviews',
    superCategoryID: 1,
    siteID: null,
    subcategories: [
      {
        categoryID: 50,
        categoryName: 'Marine Electronics',
        superCategoryID: 49,
        siteID: null
      },
      {
        categoryID: 109,
        categoryName: 'Fishing Gear',
        superCategoryID: 1,
        siteID: null
      },
      {
        categoryID: 110,
        categoryName: 'Marine Products',
        superCategoryID: 1,
        siteID: null
      },
      {
        categoryID: 111,
        categoryName: 'Trailers',
        superCategoryID: 1,
        siteID: null
      }
    ]
  },
  {
    categoryID: 30,
    categoryName: 'Features',
    superCategoryID: null,
    siteID: null,
    subcategories: [
      {
        categoryID: 113,
        categoryName: 'Destinations',
        superCategoryID: 30,
        siteID: null
      },
      {
        categoryID: 112,
        categoryName: 'Buyers Guides',
        superCategoryID: 30,
        siteID: null
      },
      {
        categoryID: 15,
        categoryName: 'News',
        superCategoryID: null,
        siteID: null
      },
      {
        categoryID: 16,
        categoryName: 'How To',
        superCategoryID: null,
        siteID: null
      },
      {
        categoryID: 33,
        categoryName: 'Videos',
        superCategoryID: 30,
        siteID: null
      },
      {
        categoryID: 32,
        categoryName: 'Editorial',
        superCategoryID: 30,
        siteID: null
      }
    ]
  },
  {
    categoryID: 22,
    categoryName: 'Subscriptions',
    href:
      'https://manage.kmail-lists.com/subscriptions/subscribe?a=PB9nsv&g=Mq46Bv',
    externalLink: true,
    superCategoryID: null,
    siteID: null,
    subcategories: [
      {
        categoryID: 24,
        categoryName: 'Digital',
        href: 'https://www.zinio.com/au/trade-a-boat-m1386',
        externalLink: true,
        superCategoryID: 22,
        siteID: null
      },
      {
        categoryID: 25,
        categoryName: 'E-newsletter',
        href: 'https://edm2.taguchimail.com/pages/17029',
        externalLink: true,
        superCategoryID: 22,
        siteID: null
      }
    ]
  }
]

export default categories
