export const brandsIDs = ['rv', 'boat'] as const

export type BrandsUnion = typeof brandsIDs[number]

export const BRANDS: Record<BrandsUnion, string> = {
  rv: 'rv',
  boat: 'boat'
} as const

export const BRAND = process.env.REACT_APP_BRAND

export const IS_BOAT = BRAND === BRANDS.boat
export const IS_RV = BRAND === BRANDS.rv

export type BrandsType = typeof BRANDS

export const Types = {
  marineTrailers: 'Marine Trailers',
  motorhomes: 'Motorhomes',
  boats: 'Boats'
}
