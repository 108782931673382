const categories = [
  {
    categoryID: 1,
    categoryName: 'Reviews',
    superCategoryID: null,
    siteID: null
  },
  {
    categoryID: 5,
    categoryName: 'Destinations',
    superCategoryID: null,
    siteID: null
  },
  {
    categoryID: 15,
    categoryName: 'News',
    superCategoryID: null,
    siteID: null
  },
  {
    categoryID: 16,
    categoryName: 'How To',
    superCategoryID: null,
    siteID: null
  },
  {
    categoryID: 30,
    categoryName: 'Features',
    superCategoryID: null,
    siteID: null
  }
]

export default categories
