import { createMuiTheme } from '@material-ui/core/styles'
import { TypographyOptions } from '@material-ui/core/styles/createTypography'

const defaultTheme = createMuiTheme()

const typography: TypographyOptions = {
  fontFamily: 'Nunito Sans, sans-serif',
  h1: {
    fontSize: 72,
    fontWeight: 500,

    [defaultTheme.breakpoints.down(600)]: {
      fontSize: 36
    }
  },
  h2: {
    fontSize: 32,
    fontWeight: 500
  },
  h3: {
    fontSize: 24,
    fontWeight: 500
  },
  h4: {
    fontSize: 20,
    fontWeight: 500
  },
  h5: {
    fontSize: 20
  },
  subtitle1: {
    fontSize: 18,
    lineHeight: 1.7
  },
  body1: {
    fontSize: 16,
    lineHeight: 1.5
  },
  body2: {
    fontSize: 16,
    lineHeight: 1.5
  }
}

export default typography
