import { useEffect } from 'react'

import { isSafari } from '~/constants/devices'

const useSmoothScrollPolyfill = () => {
  const setupPolyfill = async () => {
    try {
      const smoothscroll = (await import('smoothscroll-polyfill')).default

      smoothscroll.polyfill()
    } catch (error) {
      Error('Loading smoothscroll-polyfill failed')
    }
  }

  useEffect(() => {
    if (isSafari) {
      setupPolyfill()
    }
  }, [])
}

export default useSmoothScrollPolyfill
