import { createAsyncThunk } from '@reduxjs/toolkit'

import Deserializer from '~/services/Deserializer'
import EmpriseGroup from '~/services/EmpriseGroup'

import { AdvancedFeature, Package, PackageParams } from '~/models/Package'

export const fetchPackages = createAsyncThunk<Package[], PackageParams>(
  'package::fetch',
  async (param, { dispatch }) => {
    const resp = await EmpriseGroup.getPackages(param)
    const data = await Deserializer.deserialize(resp)
    dispatch(fetchFeatures())

    return data
  }
)

export const fetchFeatures = createAsyncThunk<AdvancedFeature[]>(
  'features::fetch',
  async () => {
    const resp = await EmpriseGroup.getAdvancedFeatures()
    const data = await Deserializer.deserialize(resp)

    return data
  }
)
