import * as React from 'react'

import cx from 'classnames'

import { Box } from '@material-ui/core'

export interface ProgressBarProps {
  // The animation delay which determine when to close the toast
  delay: number

  // Whether or not the animation is running or paused
  isRunning: boolean

  // Func to close the current toast
  closeToast: () => void
  // Hide or not the progress bar
  hide?: boolean

  // Optional className
  className?: any

  // Optional inline style
  style?: React.CSSProperties

  // Tell wether or not controlled progress bar is used
  controlledProgress?: boolean

  // Controlled progress value
  progress?: number | string

  // Support rtl content
  rtl?: boolean

  // Tell if the component is visible on screen or not
  isIn?: boolean
}

const ProgressBar = ({
  delay,
  isRunning,
  closeToast,
  hide,
  className,
  style: userStyle,
  controlledProgress,
  progress,
  rtl,
  isIn
}: ProgressBarProps) => {
  const style: React.CSSProperties = {
    ...userStyle,
    animationDuration: `${delay}ms`,
    animationPlayState: isRunning ? 'running' : 'paused',
    opacity: hide ? 0 : 1
  }

  if (controlledProgress) style.transform = `scaleX(${progress})`
  const defaultClassName = cx(
    `toast__progress-bar`,
    controlledProgress
      ? `toast__progress-bar--controlled`
      : `toast__progress-bar--animated`,
    {
      [`toast__progress-bar--rtl`]: rtl
    }
  )
  const classNames = cx(defaultClassName, className)

  // controlledProgress is derived from progress
  // so if controlledProgress is set
  // it means that this is also the case for progress
  const animationEvent = {
    [controlledProgress && progress! >= 1
      ? 'onTransitionEnd'
      : 'onAnimationEnd']:
      controlledProgress && progress! < 1
        ? null
        : () => {
            isIn && closeToast()
          }
  }

  return (
    <Box
      role="progressbar"
      aria-hidden={hide ? 'true' : 'false'}
      aria-label="notification timer"
      className={classNames}
      style={style}
      {...animationEvent}
    />
  )
}

export default ProgressBar
