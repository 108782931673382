import { useRouter } from 'next/router'
import React, { createContext, useRef, useContext, useEffect } from 'react'

interface HValidation {
  prevHistory
}

const getPrevHistory = (() => {
  let path = ''
  return (newPath: string) => {
    const currentPath = path
    path = newPath
    return currentPath
  }
})()

const HistoryContext = createContext<HValidation>({} as HValidation)

export const HistoryProvider: React.FC = ({ children }) => {
  const router = useRouter()
  const prevHistory = useRef<string>('')

  useEffect(() => {
    if (router.isReady) {
      prevHistory.current = getPrevHistory(router.asPath)
    }
  }, [router.isReady, router.asPath])

  return (
    <HistoryContext.Provider
      value={{
        prevHistory
      }}
    >
      {children}
    </HistoryContext.Provider>
  )
}

export function useHistory(): HValidation {
  const context = useContext(HistoryContext)
  return context
}
