export enum STORAGE {
  authToken = 'auth_token',
  sellWizardForm = 'Sell_Wizard_Form',
  sellWizardState = 'sell_Wizard_State',
  sellPackage = 'sell_Package',
  userEmail = 'user_email',
  confirmationToken = 'confirmation_token',
  resetToken = 'reset_password_token'
}

export enum CACHE_KEYS {
  home = 'home',
  types = 'types',
  categories = 'categories',
  locations = 'locations',
  makes = 'makes',
  models = 'models',
  series = 'series',
  severalFeaturedArticles = 'several_featured_articles',
  newsReviews = 'news_reviws',
  finance = 'finance',
  listings = 'listings',
  popupSimilarListings = 'popup_similar_listings'
}

export enum BRAND {
  boat = 'boat',
  rv = 'rv'
}

export const IS_BROWSER = typeof window !== 'undefined'

export const SITE_URL = process.env.REACT_APP_URL

export const API_URL = process.env.REACT_APP_API || ''

export const GOOGLE_RECAPTCHA_SITE_KEY =
  process.env.GOOGLE_RECAPTCHA_SITE_KEY || ''

export const MAX_CHOOSE_YEAR = new Date().getFullYear() + 4

export const MUTUAL_CONVERSION_FIELDS = [
  'length',
  'length_feet',
  'width',
  'width_feet',
  'boat_trailer_length',
  'boat_trailer_length_feet',
  'boat_trailer_width',
  'boat_trailer_width_feet',
  'boat_draft',
  'year',
  'berths',
  'tow_weight',
  'atm'
]

export const DIFFERENCE_METERS_FEETS = 3.2808

export const VALID_KEYS = [
  '(',
  ')',
  '-',
  '0',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  'Backspace',
  'Delete',
  'ArrowLeft',
  'ArrowRight'
]

export const PACKAGE = {
  economy: '3',
  plus: '4',
  premium: '5'
}

export const PAYPAL_PAYMENT_LINKS = {
  return_url: '/congratulation',
  cancel_url: '/account/dashboard'
}

export const LISTING_FORM_STEP_NAMES = {
  aboutItem: 'About Item',
  packages: 'Packages',
  itemDetails: 'Item Details',
  additionalInfo: 'Additional Info',
  additionalFeatures: 'Additional Features',
  contactInfo: 'Contact Info',
  photos: 'Photos',
  video: 'Video',
  reviewAd: 'Review Ad',
  checkout: 'Checkout'
}
