/* eslint-disable @typescript-eslint/no-var-requires,global-require, no-param-reassign */
import objectPath from 'object-path'

import { Listing } from '~/models/Listing/interface'

import { BrandsType, BRAND } from './constants'

export const getEntityByBrand = <ReturnType>(
  entities: Record<keyof BrandsType, any>
): ReturnType => {
  const siteType = BRAND?.toLowerCase()

  if (entities[siteType]) {
    return entities[siteType]
  }

  return entities.boat
}

export const getPath = (path: string): string =>
  path.replace(/\[brand\]/gim, BRAND)

const messages = getEntityByBrand<() => any>({
  rv: () => require('./messages/rv.json'),
  boat: () => require('./messages/boat.json')
})()

export const formatMessage = (
  key: string,
  options?: { values: { [key: string]: any } }
): string => {
  const stringValue = objectPath.get(messages, key)
  const { values = {} } = options || {}

  if (!stringValue) return key

  const entries = Object.entries(values)

  if (entries.length) {
    return entries.reduce((acc, [key, value]) => {
      const regexp = new RegExp(`{${key}}`, 'gm')
      acc = acc.replace(regexp, value)

      return acc
    }, stringValue)
  }

  return stringValue
}

// export const getSpecifications = async (
//   data: Partial<Listing>,
//   scale?: number
// ) => {
//   const getSpecs = (await import(getPath('./data/specifications.[brand]')))
//     .default
//   const specs = getSpecs(data, scale || 1)

//   return specs
// }

export const getSpecifications = (data: Partial<Listing>, scale?: number) =>
  getEntityByBrand<() => (data: Partial<Listing>, scale?: number) => any>({
    rv: () => require('./data/specifications.rv').default,
    boat: () => require('./data/specifications.boat').default
  })()(data, scale || 1)

export const blogCategories = getEntityByBrand<() => any>({
  rv: () => require('./data/categories.rv').default,
  boat: () => require('./data/categories.boat').default
})()

export const typeRedirects = getEntityByBrand<() => any>({
  rv: () => require('./data/typeRedirects.rv').default,
  boat: () => require('./data/typeRedirects.boat').default
})()
