import { createAsyncThunk } from '@reduxjs/toolkit'

import Deserializer from '~/services/Deserializer'
import EmpriseGroup from '~/services/EmpriseGroup'

import { Listing } from '~/models/Listing/interface'
import { Pagy } from '~/models/Pagy'

export const fetchListings = createAsyncThunk<
  { data: Listing[]; pagy: Pagy; meta: any },
  any
>('listings::fetch', async (search) => {
  const {
    pagy,
    listings,
    featured_listings,
    listings_meta
  } = await EmpriseGroup.getListings(search)
  const data = await Deserializer.deserialize(listings)
  const featured = await Deserializer.deserialize(featured_listings)

  return { data: [...featured, ...data], pagy, meta: listings_meta }
})
