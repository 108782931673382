import { createSlice } from '@reduxjs/toolkit'

import { Listing } from '~/models/Listing/interface'
import { Pagy } from '~/models/Pagy'

import { fetchListings } from './listings.actions'

export type ListingMetaProps = {
  max_price: string
  min_price: string
  years: number[]
  max_atm: string
  max_berths: string
  max_length: string
  max_tow_weight: string
  min_atm: string
  min_berths: string
  min_length: string
  min_tow_weight: string
  min_draft: string | number
  max_draft: string | number
  [key: string]: any
}

interface State {
  loading: boolean
  loaded: boolean
  data: Listing[]
  meta: ListingMetaProps
  pagy: Pagy
}

const initialState: State = {
  loading: true,
  loaded: false,
  data: [],
  meta: null,
  pagy: null
}

const listings = createSlice({
  name: 'listings',
  initialState,
  reducers: {
    setFavorite: (state, { payload }) => {
      const listing = state.data.find((item) => item.id === payload.id)
      listing.favorite = payload.favorite
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchListings.pending, (state) => {
      state.loading = true
    })
    builder.addCase(fetchListings.fulfilled, (state, { payload }) => {
      state.data = payload.data
      state.pagy = payload.pagy
      state.meta = payload.meta
      state.loading = false
      state.loaded = true
    })
    builder.addCase(fetchListings.rejected, (state) => {
      state.loading = false
    })
  }
})

export const { actions } = listings
export default listings.reducer
