import React from 'react'

import { getCategoryLogo } from '~/utils/functions'

import ConditionIcon from '~/assets/icons/specifications/condition.svg?sprite'
import FuelIcon from '~/assets/icons/specifications/fuel.svg?sprite'
import HullIcon from '~/assets/icons/specifications/hull.svg?sprite'
import LocationIcon from '~/assets/icons/specifications/location.svg?sprite'
import SellerIcon from '~/assets/icons/specifications/seller.svg?sprite'

import { Listing } from '~/models/Listing/interface'

// import LengthIcon from '~/assets/icons/specifications/length.svg?sprite'

const getSpecifications = (data: Partial<Listing>, scale = 1) => {
  const logo = getCategoryLogo(data?.type?.name)

  return [
    data.location?.name && {
      label: 'Location',
      value: data.location?.name,
      icon: <LocationIcon transform={`scale(${scale})`} />
    },
    data?.fuel_type?.name && {
      label: 'Fuel',
      value: data?.fuel_type?.name,
      icon: <FuelIcon transform={`scale(${scale})`} />
    },
    data?.category?.name && {
      label: 'Category',
      value: data?.category?.name,
      icon: data?.type?.logo ? (
        <img
          src={data?.type?.logo?.url}
          alt={data?.type?.name}
          style={{ maxWidth: '100%', maxHeight: '100%' }}
        />
      ) : (
        <img
          src={logo}
          alt={data?.type?.name}
          style={{ maxWidth: '100%', maxHeight: '100%' }}
        />
      )
    },
    // data.length && {
    //   label: 'Length',
    //   value: data.hull_type,
    //   icon: <LengthIcon  transform={`scale(${scale})`} />
    // },
    data?.hull_type?.name && {
      label: 'Hull',
      value: data?.hull_type?.name,
      icon: <HullIcon transform={`scale(${scale})`} />
    },
    data?.condition && {
      label: 'Condition',
      value: data?.condition,
      icon: <ConditionIcon transform={`scale(${scale})`} />
    },
    data?.seller_type && {
      label: 'Seller',
      value:
        data?.seller_type !== 'User' ? data?.seller_type : 'Private Seller',
      icon: <SellerIcon transform={`scale(${scale})`} />
    }
  ].filter(Boolean)
}

export default getSpecifications
