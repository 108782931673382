import React from 'react'

import { Box } from '@material-ui/core'

import BerthIcon from '~/assets/icons/specifications/berth.svg?sprite'
import CarIcon from '~/assets/icons/specifications/caravan.svg?sprite'
import ConditionIcon from '~/assets/icons/specifications/condition.svg?sprite'
import FuelIcon from '~/assets/icons/specifications/fuel.svg?sprite'
import LocationIcon from '~/assets/icons/specifications/location.svg?sprite'
import OdometerIcon from '~/assets/icons/specifications/odometer.svg?sprite'
import SellerIcon from '~/assets/icons/specifications/seller.svg?sprite'
import SpeedIcon from '~/assets/icons/specifications/speed.svg?sprite'

import { Listing } from '~/models/Listing/interface'

const getSpecifications = (data: Partial<Listing>, scale = 1) =>
  [
    data.location?.name && {
      label: 'Location',
      value: data.location.name,
      icon: <LocationIcon transform={`scale(${scale})`} />
    },
    data.fuel_type?.name && {
      label: 'Fuel',
      value: data.fuel_type.name,
      icon: <FuelIcon transform={`scale(${scale})`} />
    },
    data.category?.name && {
      label: 'Category',
      value: data.category.name,
      icon: data.type?.logo ? (
        <img
          src={data.type?.logo?.url}
          alt={data.type?.name}
          style={{ maxWidth: '100%', maxHeight: '100%' }}
        />
      ) : (
        <CarIcon transform={`scale(${scale})`} />
      )
    },
    data.color && {
      label: 'Color',
      value: data.color,
      icon: <Box borderRadius="50%" bgcolor="grey" width={30} height={30} />
    },
    data.number_of_berths && {
      label: 'Berth',
      value: data.number_of_berths,
      icon: <BerthIcon transform={`scale(${scale})`} />
    },
    data?.odometer && {
      label: 'Odometer',
      value: `${data.odometer} km`,
      icon: (
        <OdometerIcon width={30} height={30} transform={`scale(${scale})`} />
      )
    },
    data.condition && {
      label: 'Condition',
      value: data.condition,
      icon: <ConditionIcon transform={`scale(${scale})`} />
    },
    data.mileage && {
      label: 'Mileage',
      value: data.mileage,
      icon: <SpeedIcon transform={`scale(${scale})`} />
    },
    data?.seller_type && {
      label: 'Seller',
      value:
        data?.seller_type !== 'User' ? data?.seller_type : 'Private Seller',
      icon: <SellerIcon transform={`scale(${scale})`} />
    }
  ].filter(Boolean)

export default getSpecifications
