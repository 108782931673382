import type { AppProps } from 'next/app'
import dynamic from 'next/dynamic'
import Head from 'next/head'
import React, { useEffect, useState } from 'react'

import { DefaultSeo } from 'next-seo'
import { QueryClient, QueryClientProvider } from 'react-query'
import { Hydrate } from 'react-query/hydration'

import CssBaseline from '@material-ui/core/CssBaseline'
import { Theme, ThemeProvider } from '@material-ui/core/styles'

import GTMScript from '~/components/scripts/GTMScript'
import { HistoryProvider } from '~/containers/History'
import ToasterContainer from '~/containers/ToasterContainer'
import withRedux from '~/containers/withRedux'

import useAutoLogin from '~/hooks/useAutoLogin'
import useIntersectionObserver from '~/hooks/useIntersectionObserver'
import useSmoothScrollPolyfill from '~/hooks/useSmoothScrollPolyfill'
import EmpriseGroup from '~/services/EmpriseGroup'
import { logoutOn403 } from '~/services/interceptors'

import { formatMessage } from '~/brands'
import theme from '~/theme'
import { NextPageWithLayout } from '~/types/next'

const ReactQueryDevtools = dynamic(() =>
  import('react-query/devtools').then((mod) => mod.ReactQueryDevtools as any)
)

declare global {
  interface GlobalEventHandlers {
    width: number
  }
  interface Window {
    dataLayer: any
    hbspt: any
  }
}

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout
  err: any
  dehydratedState: any
  pageProps: any
}

EmpriseGroup.appendErrorInterceptor(logoutOn403)

function MyApp({
  Component,
  pageProps,
  err,
  dehydratedState
}: AppPropsWithLayout) {
  const [queryClient] = useState(() => new QueryClient())

  useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector('#jss-server-side')
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles)
    }
  }, [])

  useAutoLogin()
  useIntersectionObserver()
  useSmoothScrollPolyfill()

  useEffect(() => {
    if (window.history.scrollRestoration) {
      window.history.scrollRestoration = 'manual'
    }
  }, [])

  const getLayout = Component.getLayout ?? ((page) => page)

  return (
    <>
      <DefaultSeo
        titleTemplate={`%s | ${formatMessage('brand.name')} | ${formatMessage(
          'brand.title'
        )}`}
      />
      <GTMScript />
      <Head>
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no user-scalable=0"
        />
      </Head>
      <ToasterContainer position="top-right" duration={5000} />
      <ThemeProvider<Theme> theme={theme}>
        <CssBaseline />
        <QueryClientProvider client={queryClient}>
          <Hydrate state={{ ...dehydratedState, ...pageProps.dehydratedState }}>
            <HistoryProvider>
              {getLayout(<Component {...pageProps} err={err} />)}
            </HistoryProvider>
          </Hydrate>
          <ReactQueryDevtools />
        </QueryClientProvider>
      </ThemeProvider>
    </>
  )
}

export default withRedux(MyApp)
