import React from 'react'

import { Provider } from 'react-redux'

import store from '~/state/store'

function withRedux<T>(WrappedComponent: React.FC<T>) {
  return (props: T): JSX.Element => (
    <Provider store={store}>
      <WrappedComponent {...props} />
    </Provider>
  )
}

export default withRedux
