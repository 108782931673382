import { PaletteOptions } from '@material-ui/core/styles/createPalette'

import { getEntityByBrand } from '~/brands'

interface PaletteType extends PaletteOptions {
  bg: {
    default: string
    primary: {
      main: string
      dark: string
    }
    secondary: string
  }
}

const tradervs: PaletteType = {
  background: {
    default: '#FFFFFF'
  },
  primary: {
    main: '#098ACB'
  },
  secondary: {
    main: '#7A7558'
  },
  text: {
    primary: '#232323',
    secondary: '#808080'
  },
  error: {
    main: '#C8462A'
  },
  success: {
    main: '#568147'
  },
  bg: {
    default: '#FFFFFF',
    primary: {
      main: '#E5EEF6',
      dark: '#D8E5EE'
    },
    secondary: '#F1EADE'
  }
}

const tradeaboat: PaletteType = {
  background: {
    default: '#FFFFFF'
  },
  primary: {
    main: '#27A4BF',
    contrastText: '#FFFFFF'
  },
  secondary: {
    main: '#3A627E'
  },
  text: {
    primary: '#232323',
    secondary: '#808080'
  },
  error: {
    main: '#C8462A'
  },
  success: {
    main: '#568147'
  },
  bg: {
    default: '#FFFFFF',
    primary: {
      main: '#E0ECEE',
      dark: '#D8E5EE'
    },
    secondary: '#F0EFEC'
  }
}

export default getEntityByBrand<PaletteType>({ rv: tradervs, boat: tradeaboat })
