import toast from 'react-hot-toast'

class ErrorHandler extends Error {
  errors: any
  _message = ''

  constructor(data: any, hideToastr?: boolean) {
    super('Error')
    const error =
      data?.errors ||
      (Array.isArray(data) && data) ||
      (typeof data === 'string' && data)

    this.errors = error
    this._message = error

    if (this.errors && !hideToastr) {
      if (typeof this.errors === 'object' && this.errors._base) {
        toast.error(this.errors._base)
      } else {
        toast.error(
          Array.isArray(this.errors) ? this.errors.join('/n') : this._message
        )
      }
    } else if (!hideToastr) {
      toast.error('Something went wrong')
    }
  }
}

export default ErrorHandler
