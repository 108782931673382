const typeRedirects = [
  {
    old: 'boats',
    new: 'Boats'
  },
  {
    old: 'engines',
    new: 'Marine%20Engines'
  },
  {
    old: 'trailers',
    new: 'Marine%20Trailers'
  },
  {
    old: 'parts',
    new: 'Marine%20Parts'
  },
  {
    old: 'marine',
    new: 'Boats'
  }
]

export default typeRedirects
