import { createSlice } from '@reduxjs/toolkit'

import { User } from '~/models/User'

export interface UserState extends Partial<User> {
  token: string
  exp: string
  type: string
  user_id: string | number
  mounted: boolean
}

export const initialState: UserState = {
  token: '',
  exp: '',
  email: '',
  name: '',
  type: '',
  role: 'guest',
  user_id: '',
  member_benefits: true,
  message_notification: true,
  receive_news: true,
  special_offers: true,
  mounted: false
}

const user = createSlice({
  name: 'user',
  initialState: { ...initialState },
  reducers: {
    login: (state, { payload }): UserState => ({
      ...state,
      ...payload
    }),
    updateUser: (state, { payload }): UserState => ({
      ...state,
      ...payload
    }),
    logout: (): UserState => ({
      ...initialState
    })
  }
})

export const { actions } = user

export default user.reducer
