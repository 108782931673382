import { logout } from '~/state/modules/user/user.actions'
import store from '~/state/store'

const UNAUTHORIZED = [403, 401]

export const logoutOn403 = (error) => {
  const { status } = error.response

  if (UNAUTHORIZED.includes(status)) {
    store.dispatch(logout())
  }

  return Promise.reject(error)
}
