export const linksCategories = [
  {
    name: 'Marine Accessories',
    href: '/search/Marine%20Accessories',
    logo: '/static/images/categories/boat/marine-accessories.png'
  },
  {
    name: 'Marine Engines',
    href: '/search/Marine%20Engines',
    logo: '/static/images/categories/boat/marine-engines.png'
  },
  {
    name: 'Motorsail',
    href: '/search/Motorsail',
    logo: '/static/images/categories/boat/motorsail.png'
  },
  {
    name: 'Powerboat',
    href: '/search/Powerboat',
    logo: '/static/images/categories/boat/power-boat.png'
  },
  {
    name: 'Power Trailer Boats',
    href: '/search/Power%20Trailer%20Boats',
    logo: '/static/images/categories/boat/power-trailer-boats.png'
  },
  {
    name: 'Sailboat',
    href: '/search/Sailboat',
    logo: '/static/images/categories/boat/sailboat.png'
  },
  {
    name: 'Trailer Boat',
    href: '/search/Trailer%20Boat',
    logo: '/static/images/categories/boat/trailer-boat.png'
  }
]
