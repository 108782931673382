import { IS_BROWSER } from './index'

export const isSafari = IS_BROWSER && navigator.userAgent.indexOf('Safari') > -1
export const isIOS =
  IS_BROWSER &&
  ([
    'iPad Simulator',
    'iPhone Simulator',
    'iPod Simulator',
    'iPad',
    'iPhone',
    'iPod'
  ].includes(navigator.platform) ||
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document))
