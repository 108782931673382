import {
  ActionReducerMapBuilder,
  createAsyncThunk,
  createSlice
} from '@reduxjs/toolkit'

import Deserializer from '~/services/Deserializer'
import EmpriseGroup from '~/services/EmpriseGroup'

import { Enqury } from '~/models/Enquiry'
import { Listing } from '~/models/Listing/interface'
import { Pagy } from '~/models/Pagy'

export const fetchEnquires = createAsyncThunk<
  { data: Enqury[]; pagy: Pagy },
  {
    page?: number
    sorting?: string
  }
>('dashboard::fetchEnquires', async ({ page, sorting }, thunkApi) => {
  try {
    const { pagy, leads } = await EmpriseGroup.getLeads({ page, sorting })
    const data = await Deserializer.deserialize(leads)

    return { pagy, data }
  } catch (error) {
    return thunkApi.rejectWithValue(error)
  }
})

export const fetchListings = createAsyncThunk<
  { data: Listing[]; pagy: Pagy },
  { page?: number; sorting?: string; by_status?: string }
>(
  'dashboard::fetchListings',
  async ({ page, sorting, by_status }, thunkApi) => {
    try {
      const { pagy, listings } = await EmpriseGroup.getUserListings({
        page,
        sorting,
        by_status
      })
      const data = await Deserializer.deserialize(listings)

      return { data, pagy }
    } catch (error) {
      return thunkApi.rejectWithValue(error)
    }
  }
)

export const fetchItems = createAsyncThunk<
  { data: Listing[]; pagy: Pagy },
  number
>('dashboard::fetchItems', async (page, thunkApi) => {
  try {
    const { pagy, listings } = await EmpriseGroup.getFavoriteListings(page)
    const data = await Deserializer.deserialize(listings)

    return { pagy, data }
  } catch (error) {
    return thunkApi.rejectWithValue(error)
  }
})

export const fetchSearches = createAsyncThunk<{ data: any[] }, number>(
  'dashboard::fetchSearches',
  async (page) => {
    const { pagy, saved_searches } = await EmpriseGroup.getSavedSearches(page)
    const data = await Deserializer.deserialize(saved_searches)

    return { pagy, data }
  }
)

type DashboardModel = {
  listings: Listing[]
  promotedListings: Listing[]
  items: Listing[]
  enquiries: Enqury[]
  searches: any[]
  viewStatistics: { [key: string]: number }
  leadStatistics: { [key: string]: number }
  contactStatistics: { [key: string]: number }
}

export const fetchDashboard = createAsyncThunk<DashboardModel, void>(
  'dashboard::fetchDashboard',
  async () => {
    const {
      last_listings,
      promoted_listings,
      favorite_listings,
      leads,
      saved_searches,
      views_number = {},
      leads_number = {},
      contact_number = {}
    } = await EmpriseGroup.getDashboard()
    let listings = null
    let promotedListings = null
    let items = null
    let enquiries = null
    let searches = null

    if (last_listings) {
      listings = await Deserializer.deserialize(last_listings)
    }
    if (promoted_listings) {
      promotedListings = await Deserializer.deserialize(promoted_listings)
    }
    if (favorite_listings) {
      items = await Deserializer.deserialize(favorite_listings)
    }
    if (leads) {
      enquiries = await Deserializer.deserialize(leads)
    }
    if (saved_searches) {
      searches = await Deserializer.deserialize(saved_searches)
    }

    return {
      listings,
      promotedListings,
      items,
      enquiries,
      searches,
      viewStatistics: views_number,
      leadStatistics: leads_number,
      contactStatistics: contact_number
    }
  }
)

type ThunkData<Data> = {
  loading: boolean
  loaded: boolean
  data: Data
  pagy?: {
    [key: string]: any
  }
}

interface State {
  listings: ThunkData<Listing[]>
  enquiries: ThunkData<Enqury[]>
  items: ThunkData<Listing[]>
  searches: ThunkData<any[]>
  viewStatistics: { [key: string]: number }
  leadStatistics: { [key: string]: number }
  contactStatistics: { [key: string]: number }
  data: {
    listings: Listing[]
    enquiries: Enqury[]
    items: Listing[]
    searches: any[]
  }
  loading: boolean
  loaded: boolean
}

const thunkData = {
  loading: false,
  loaded: false,
  data: [],
  pagy: {}
}

const initialState: State = {
  listings: { ...thunkData },
  enquiries: { ...thunkData },
  items: { ...thunkData },
  searches: { ...thunkData },
  viewStatistics: {},
  leadStatistics: {},
  contactStatistics: {},
  data: {
    listings: [],
    enquiries: [],
    items: [],
    searches: []
  },
  loading: true,
  loaded: false
}

const buildThunkReducer = (
  builder: ActionReducerMapBuilder<State>,
  thunkAction,
  entity: keyof Omit<
    State,
    'loading' | 'loaded' | 'viewStatistics' | 'leadStatistics' | 'data'
  >
) => {
  builder.addCase(thunkAction.pending, (state) => {
    state[entity].loading = true
  })
  builder.addCase(thunkAction.fulfilled, (state, { payload }) => {
    state[entity].data = payload.data
    state[entity].pagy = payload.pagy
    state[entity].loading = false
    state[entity].loaded = true
  })
  builder.addCase(thunkAction.rejected, (state) => {
    state[entity].loading = false
  })
}

const dashboard = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    removeItem: (state, { payload: { id } }) => {
      // TODO: refactor
      state.data.items = state.data.items.filter((item) => item.id !== id)
      state.items.data = state.items.data.filter((item) => item.id !== id)
    },
    removeListing: (state, { payload: { id } }) => {
      // TODO: refactor
      state.data.listings = state.data.listings.filter(
        (listing) => listing.id !== id
      )
      state.listings.data = state.listings.data.filter(
        (listing) => listing.id !== id
      )
    },
    paidListing: (state, { payload: { id } }) => {
      // TODO: refactor
      const dashboard = state.data.listings.find((listing) => listing.id === id)
      const listing = state.listings.data.find((listing) => listing.id === id)

      if (dashboard) dashboard.paid = true
      if (listing) listing.paid = true
    },
    setSoldListing: (state, { payload: { id } }) => {
      // TODO: refactor
      const dashboard = state.data.listings.find((listing) => listing.id === id)
      const listing = state.listings.data.find((listing) => listing.id === id)

      if (dashboard) dashboard.status = 'sold'
      if (listing) listing.status = 'sold'
    },
    removeSearch: (state, { payload: { id } }) => {
      // TODO: refactor
      state.data.searches = state.data.searches.filter(
        (search) => search.id !== id
      )
      state.searches.data = state.searches.data.filter(
        (search) => search.id !== id
      )
    },
    setStatusEnquiry: (state, { payload: { id, status } }) => {
      // TODO: refactor
      const dashboard = state.data.enquiries.find(
        (enquiry) => enquiry.id === id
      )
      const listing = state.enquiries.data.find((enquiry) => enquiry.id === id)

      if (dashboard) dashboard.status = status
      if (listing) listing.status = status
    },
    setNotesEnquiry: (state, { payload: { id, notes } }) => {
      // TODO: refactor
      const dashboard = state.data.enquiries.find(
        (enquiry) => enquiry.id === id
      )
      const listing = state.enquiries.data.find((enquiry) => enquiry.id === id)

      if (dashboard) dashboard.seller_notes = notes
      if (listing) listing.seller_notes = notes
    }
  },
  extraReducers: (builder) => {
    buildThunkReducer(builder, fetchEnquires, 'enquiries')
    buildThunkReducer(builder, fetchListings, 'listings')
    buildThunkReducer(builder, fetchItems, 'items')
    buildThunkReducer(builder, fetchSearches, 'searches')
    builder.addCase(fetchDashboard.pending, (state) => {
      state.loading = true
    })
    builder.addCase(fetchDashboard.fulfilled, (state, { payload }) => {
      state.data.listings = payload.listings
      state.data.enquiries = payload.enquiries
      state.data.items = payload.items
      state.data.searches = payload.searches || []
      state.viewStatistics = payload.viewStatistics
      state.leadStatistics = payload.leadStatistics
      state.contactStatistics = payload.contactStatistics
      state.loading = false
      state.loaded = true
    })
    builder.addCase(fetchDashboard.rejected, (state) => {
      state.loading = false
    })
  }
})

export const { actions } = dashboard
export default dashboard.reducer
