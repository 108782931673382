import { createSlice } from '@reduxjs/toolkit'

import { Dealer } from '~/models/Dealer'
import { Pagy } from '~/models/Pagy'

import { fetchDealers } from './dealers.actions'

interface State {
  loading: boolean
  loaded: boolean
  data: Dealer[]
  pagy: Pagy
}

const initialState: State = {
  loading: true,
  loaded: false,
  data: [],
  pagy: null
}

const dealers = createSlice({
  name: 'dealers',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchDealers.pending, (state) => {
      state.loading = true
    })
    builder.addCase(fetchDealers.fulfilled, (state, { payload }) => {
      state.data = payload.data
      state.pagy = payload.pagy
      state.loading = false
      state.loaded = true
    })
    builder.addCase(fetchDealers.rejected, (state) => {
      state.loading = false
    })
  }
})

export const { actions } = dealers
export default dealers.reducer
